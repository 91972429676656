import React, { useState, useEffect, useRef } from "react";
import { Typography, Input, Form, message, Alert } from "antd";
import Gradientbutton from "../../comp/reuse/gradientbutton";
import Myalert from "../../comp/reuse/alert";
import { useSearchParams } from "react-router-dom";
import usePost from "../../hooks/usePost";
import Cookies from "js-cookie";
import EmailVerify from "./comp/modals/emailverify";

const { Title, Text } = Typography;

const Otp = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const rpw = searchParams.get("rpw");
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState(""); // Single input for OTP
  const { postData, message: dbMessage } = usePost();

  const onFinish = async () => {
    if (otp.length === 6) {
      await postData(
        "otp?token=" + token + (rpw ? "&rpw=" + true : ""),
        { code: otp },
        (d) => {
          const { id, pwd, token: pwdToken } = d;
          if (!pwd) {
            Cookies.set("user", id, { expires: 30 });
            window.location.href = "/";
          } else {
            window.location.href = "/reset-password-verify?token=" + pwdToken;
          }
        }
      );
    } else {
      message.error("Please enter a valid 6-digit OTP.");
    }
  };

  const resendOtp = async () => {
    setOtp(""); // Clear the input field
    await postData(
      "resend?token=" + token + (rpw ? "&rpw=" + true : ""),
      {},
      () => {}
    );
  };
  useEffect(() => {
    Cookies.remove("user");
  }, []);

  return (
    <div className="w-100">
      <div style={{ textAlign: "center" }}>
        <Title className="m-0" level={4}>
          OTP VERIFICATION
        </Title>
        <div className="mt-5">
          <Myalert
            closable={true}
            showIcon={true}
            title={""}
            desc="Your code is only valid before 5 mins."
          />
          <div className="my-4">
            <Text className="my-4">
              Please enter the 6-digit OTP sent to your email.
            </Text>
          </div>
          <Form
            name="otpVerification"
            onFinish={onFinish}
            layout="vertical"
            className="otp-form"
            style={{ marginTop: "24px" }}
          >
            <Form.Item
              name="otp"
              rules={[
                { required: true, message: "Please enter the OTP!" },
                { len: 6, message: "OTP must be exactly 6 digits!" },
              ]}
            >
              <Input
                type="text"
                maxLength="6"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter 6-digit OTP"
                style={{
                  width: "50%",
                  height: "40px",
                  textAlign: "center",
                  fontSize: "18px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </Form.Item>
            {dbMessage && dbMessage?.error_code === 1 && (
              <Alert
                description={
                  <>
                    Token does not exist!. Go to{" "}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        resendOtp();
                      }}
                      href="/#"
                    >
                      resend OTP
                    </a>
                  </>
                }
                type="error"
                showIcon={false}
                className="px-3 py-2"
              />
            )}
            {dbMessage && dbMessage?.error_code === 2 && (
              <Alert
                description={
                  <>
                    Code has expired{" "}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        resendOtp();
                      }}
                      href="/#"
                    >
                      resend OTP
                    </a>
                  </>
                }
                type="error"
                showIcon={false}
                className="px-3 py-2"
              />
            )}
            {dbMessage && dbMessage?.error_code === 3 && (
              <Alert
                description={<>Incorrect code. Check your code and retry.</>}
                type="error"
                showIcon={false}
                className="px-3 py-2"
              />
            )}
            {dbMessage && dbMessage?.error_code === 4 && (
              <Alert
                description={
                  <>
                    You're already verified. <a href="/"> Go home</a>
                  </>
                }
                type="error"
                showIcon={false}
                className="px-3 py-2"
              />
            )}
            {dbMessage && dbMessage?.error_code === 5 && (
              <Alert
                description={
                  <>
                    Invalid token. Use email instead{" "}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setOpen(true);
                      }}
                      href="/#"
                    >
                      Use Email
                    </a>
                  </>
                }
                type="error"
                showIcon={false}
                className="px-3 py-2"
              />
            )}
            {dbMessage && dbMessage?.type === "success" && (
              <Myalert
                closable={false}
                showIcon={false}
                title={""}
                desc={dbMessage?.msg}
              />
            )}
            <Form.Item>
              <Gradientbutton
                type={true}
                mClasses="float-left mt-4 ml-3"
                btntext="VERIFY"
                size="large"
                disabled={otp.length !== 6}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <EmailVerify open={open} close={() => setOpen(false)} />
    </div>
  );
};

export default Otp;
