import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Input,
  Button,
  message,
  Select,
  Radio,
  Badge,
  Modal,
  QRCode,
} from "antd";
import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Gradientbutton from "../../../../comp/reuse/gradientbutton";
import Mtooltip from "../../../../comp/reuse/tooltip";
import Myalert from "../../../../comp/reuse/alert";
import { Context } from "../../../../AppContext";

const { Title, Text } = Typography;

const USDT = ({ amount = "", open = false, close = () => {}, wallet }) => {
  const { modalAction } = Context();
  const [inputValue, setInputValue] = useState(wallet);
  useEffect(() => {
    setInputValue(wallet);
  }, [wallet]);

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue).then(() => {
      message.success("Wallet address has been copied.");
    });
  };
  function handleUSDT(params) {
    window.location.href = "/confirm-usdt?amount=" + amount;
  }
  return (
    <Modal footer={null} open={open} onOk={() => {}} onCancel={close}>
      <div className="w-100">
        <Title className="m-0 text-center" level={4}>
          SEND {amount} USDT
        </Title>
        <div className="mt-4">
          <Myalert
            title={""}
            showIcon={false}
            type="warning"
            desc={`
                Ensure to send funds only to this wallet and TRC network. Else,
                you could lose your funds.`}
          />
        </div>
        <div
          className="d-flex align-items-center flex-column"
          style={{ marginTop: 30 }}
        >
          <QRCode value={inputValue} />
          <div
            className="input-copy-container d-flex align-items-center justify-content-center"
            style={{ maxWidth: 300, minWidth: 200, marginTop: 16 }}
          >
            <Input
              value={inputValue}
              readOnly
              suffix={
                <Button
                  icon={<CopyOutlined />}
                  onClick={handleCopy}
                  type="default"
                  variant="outlined"
                  size="small"
                  style={{ borderRadius: 4 }}
                >
                  Copy
                </Button>
              }
              style={{
                borderRadius: "4px 0 0 4px",
                border: "1px solid #d9d9d9",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
            <Mtooltip mClasses="ml-2" title={"Copy wallet address."} />
          </div>

          <Gradientbutton
            btntext="COMPLETE"
            mClasses="mt-5 d-flex align-items-center justify-content-end w-100"
            size="large"
            //   loading={true}
            callBack={handleUSDT}
          />
        </div>
      </div>
    </Modal>
  );
};

export default USDT;
