import React from 'react';

const Preloader = () => {
  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.91)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const animationStyle = {
    display: 'flex',
    
  };

  const letterStyle = {
    fontSize: '50px',
    fontWeight: 'bold',
    background: 'linear-gradient(90deg, #1890ff, #ff4d4f)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: 'bounce 1.5s infinite',
  };

  const keyframesStyle = `
    @keyframes bounce {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-20px);
      }
    }
  `;

  return (
    <div style={containerStyle}>
      <style>{keyframesStyle}</style>
      <div style={animationStyle}>
        <span style={{ ...letterStyle, animationDelay: '0s' }}>T</span>
        <span style={{ ...letterStyle, animationDelay: '0.1s' }}>R</span>
        <span style={{ ...letterStyle, animationDelay: '0.2s' }}>A</span>
        <span style={{ ...letterStyle, animationDelay: '0.3s' }}>D</span>
        <span style={{ ...letterStyle, animationDelay: '0.4s' }}>E</span>
        <span style={{ ...letterStyle, animationDelay: '0.5s' }}>S</span>
        <span style={{ ...letterStyle, animationDelay: '0.6s' }}>T</span>
        <span style={{ ...letterStyle, animationDelay: '0.7s' }}>E</span>
        <span style={{ ...letterStyle, animationDelay: '0.8s' }}>R</span>
      </div>
    </div>
  );
};

export default Preloader;