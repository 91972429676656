import React from "react";
import { Button, Result } from "antd";
import Gradientbutton from "../../comp/reuse/gradientbutton";
const Error404 = () => (
  <Result
    status="404"
    title="Page Not Found"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Gradientbutton
        callBack={() => (window.location.href = "/")}
        btntext="HOME"
      />
    }
  />
);
export default Error404;
