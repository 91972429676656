import React from "react";
import {  Result } from "antd";
import Gradientbutton from "../../comp/reuse/gradientbutton";
const Error500 = () => (
  <Result
    status="500"
    title="Internal Sever Error"
    subTitle="Sorry, something went wrong."
    extra={
      <Gradientbutton
        callBack={() => (window.location.href = "/")}
        btntext="HOME"
      />
    }
  />
);
export default Error500;
