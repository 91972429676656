import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Tooltip,
  Input,
  Button,
  message,
  ConfigProvider,
  Alert,
  Modal,
} from "antd";
import {
  InfoCircleOutlined,
  CopyOutlined,
  BellOutlined,
} from "@ant-design/icons";
import Gradientbutton from "../../comp/reuse/gradientbutton";
import Mtooltip from "../../comp/reuse/tooltip";
import Myalert from "../../comp/reuse/alert";
import { Context } from "../../AppContext";
import useGetPrivateData from "./../../hooks/useGetPrivateData";
import {
  adjustPoolValue,
  generatePool,
  getRandomNumber,
  stringToArray,
} from "../../util";
import Authorized from "../../comp/reuse/authorized";

const { Title, Text } = Typography;

const Trade = ({ user, trade, notCount = 0 }) => {
  const { name, link, bal, countryCode, countryName, isVerified } = user;
  const {
    pool,
    min,
    max,
    rangeInvestors,
    readingString,
    interval: dbInterval,
    percentage: per,
    index: dbIndex,
    investors: dbInvestors,
    investorInterval: dbInvestorsInterval,
    active,
    isWeekend,
  } = trade;
  const { modalAction, setGLobalPrivateLoading } = Context();
  const [inputValue, setInputValue] = useState(link);
  const [funds, setFunds] = useState(pool);
  const [percentage, setPercentage] = useState();
  const [dataInterval, setDataInterval] = useState();
  const [data, setData] = useState();
  const [userBal, setUserBal] = useState(0);
  const [index, setIndex] = useState();
  const [investors, setInvestors] = useState();
  const [investorInterval, setInvestorsInterval] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalText, setModaltext] = useState();
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setInputValue(link);
  }, [user]);
  useEffect(() => {
    setPercentage(Number(per));
    setFunds(Number(pool));
    setData(stringToArray(readingString));
    setIndex(Number(dbIndex));
    setDataInterval(Number(dbInterval));
    setInvestors(Number(dbInvestors));
    setInvestorsInterval(Number(dbInvestorsInterval));
  }, [trade]);

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue).then(() => {
      message.success("Referral Code has been copied to clipboard.");
    });
  };
  function showModal(msg) {
    setGLobalPrivateLoading(true);
    setTimeout(() => {
      modalAction("error", msg);
      setGLobalPrivateLoading(false);
    }, 1000 * 5);
  }
  useEffect(() => {
    if (isWeekend) {
      setInvestors(Number(dbInvestors)); // Reset investors to initial value
      return; // Do not run the interval
    }
    const interval = setInterval(() => {
      updateInvestors();
    }, investorInterval);

    return () => clearInterval(interval);
  }, [investorInterval, investors, isWeekend]);
  useEffect(() => {
    if (isWeekend) {
      setFunds(Number(pool)); // Reset funds to pool
      return; // Do not run the interval
    }
    const interval = setInterval(() => {
      updatePercentageAndFunds();
    }, dataInterval);

    return () => clearInterval(interval);
  }, [dataInterval, index, percentage, funds, isWeekend]);

  const updatePercentageAndFunds = () => {
    if (!data || data.length === 0) return;

    const currentValue = Number(data[index]);
    const newPercentage = Number(percentage) + currentValue * 0.01;
    // Ensure the new percentage is within the min and max range
    const clampedPercentage = Math.max(min, Math.min(newPercentage, max));
    const newFunds = Number(pool) + Number(pool) * clampedPercentage;
    setPercentage(clampedPercentage.toFixed(2));
    setFunds(Number(newFunds).toFixed(2));

    // Update the index, reset to 0 if it reaches the end of the array
    const newIndex = (index + 1) % data.length;
    setIndex(newIndex);
  };
  function updateInvestors(params) {
    if (!data || data.length === 0) return;
    const currentValue = Number(data[index]);
    const newInvestor = investors + currentValue;
    setInvestors(newInvestor);
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="">
          {" "}
          <Title className="" level={4}>
            Hello {name || "Trader"},
          </Title>
          {
            <Title
              className=""
              style={{ fontSize: 12, marginTop: -10 }}
              level={5}
            >
              Bal: {Number(Number(bal)?.toFixed(2)).toLocaleString() || "0.00"}{" "}
              USD
            </Title>
          }
        </div>
        <div className="d-flex align-items-center">
          <Tooltip title={countryName}>
            <div className="country-icon" style={{ marginRight: 16 }}>
              <img
                src={`https://flagcdn.com/32x24/${countryCode?.toLowerCase()}.png`}
                alt={countryCode?.toLowerCase()}
                style={{ width: 32, height: 24 }}
              />
            </div>
          </Tooltip>

          <div className="notification-icon" style={{ position: "relative" }}>
            <Button
              onClick={() =>
                isVerified
                  ? (window.location.href = "/notification")
                  : setIsModalVisible(true)
              }
              className="p-1"
            >
              <BellOutlined style={{ fontSize: 24 }} />
            </Button>
            <span
              className="badge"
              style={{
                position: "absolute",
                top: -5,
                right: -5,
                background: "#f5222d",
                borderRadius: "50%",
                color: "white",
                // padding: "0 6px",
                fontSize: 12,
              }}
            >
              {notCount}
            </span>
          </div>
        </div>
      </div>

      <div
        className="text-center d-flex flex-column align-items-center"
        style={{ marginTop: 100 }}
      >
        <Title level={2} className="m-0">
          ${Number(funds)?.toLocaleString()}
          <sup style={{ marginLeft: 4, verticalAlign: "top" }}>
            <Text type="success">
              +{(Number(percentage) * 100).toFixed(0)}%
            </Text>
            <Mtooltip
              title={`We're ${(Number(percentage) * 100).toFixed(
                0
              )}% up in profit`}
            />
          </sup>
        </Title>
        <Text style={{ fontSize: 12 }} type="secondary">
          {isWeekend ? (
            <>No trades on weekends. Please check back later.</>
          ) : active ? (
            <>
              You and other {investors?.toLocaleString()} traders have joined
              the trade.
            </>
          ) : (
            <>{investors?.toLocaleString()} traders have joined the trade.</>
          )}
        </Text>

        <Gradientbutton
          btntext="ENTER TRADE"
          mClasses="my-3 mr-3 d-flex flex-column align-items-center justify-content-center"
          size="large"
          callBack={() =>
            isVerified
              ? isWeekend
                ? (setModaltext("Trade is closed for the weekend."),
                  setIsModalVisible(true))
                : (window.location.href = "/activate")
              : setIsModalVisible(true)
          }
        />
        <div
          className="input-copy-container d-flex align-items-center justify-content-center"
          style={{ maxWidth: 300, minWidth: 200, marginTop: 16 }}
        >
          {link && (
            <>
              <Input
                value={inputValue}
                readOnly
                suffix={
                  <Button
                    icon={<CopyOutlined />}
                    onClick={handleCopy}
                    type="default"
                    variant="outlined"
                    size="small"
                    style={{ borderRadius: 4 }}
                  >
                    Copy
                  </Button>
                }
                style={{
                  borderRadius: "4px 0 0 4px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
              <Mtooltip title={"Copy your referral code."} />
            </>
          )}
        </div>
      </div>
      <div className="mt-4">
        <Alert
          title={"Live Trades"}
          type="info"
          description={
            <>
              If you wish to monitor your trade live on MT4 app, you can request
              for logins by clicking{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  isVerified
                    ? showModal("Error occured!. Please, try again later.")
                    : setIsModalVisible(true);
                }}
                href="/#"
              >
                here
              </a>
              .
            </>
          }
        />
      </div>
      <Authorized
        open={isModalVisible}
        close={() => setIsModalVisible(false)}
        text={modalText}
      />
    </div>
  );
};

export default Trade;
