import React, { useContext, useEffect, useState } from "react";
import { Table, Typography, Button, Badge } from "antd";

const { Title } = Typography;

const UserTradeHistories = (
  {history = [],
  pageData = {},
  callBack = async () => null}
) => {
  const { currentPage, totalPages } = pageData;
  const [data, setData] = useState([]);
  const [tPages, setTPages] = useState();
  const [page, setPage] = useState(1);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 60,
      render: (text) => <a>{text}</a>,
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Amount ($)",
      dataIndex: "amount",
      key: "amount",
      width: 500,
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Profit ($)",
      dataIndex: "profit",
      key: "profit",
      width: 500,
      sorter: (a, b) => a.profit - b.profit,
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 80,
      filters: [
        { text: "Compound", value: "compound" },
        { text: "Simple", value: "simple" },
      ],
      onFilter: (value, record) => record.type.includes(value),
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Entry Time",
      dataIndex: "entry_time",
      key: "entry_time",
      width: 500,
      sorter: (a, b) => new Date(a.entry_time) - new Date(b.entry_time),
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 80,
      filters: [
        { text: "Active", value: "active" },
        { text: "Ended", value: "ended" },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Badge
          status={status === "active" ? "success" : "error"}
          text={status}
        />
      ),
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Exit Time",
      dataIndex: "exit_time",
      key: "exit_time",
      width: 500,
      sorter: (a, b) => new Date(a.exit_time) - new Date(b.exit_time),
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
   
  ];
  useEffect(() => {
    setData(history);
  }, [history]);
  useEffect(() => {
    setPage(currentPage);
    setTPages(totalPages);
  }, [pageData]);
  

  return (
    <div className="w-100 text-center">
      <Title className="m-0 text-center" level={4}>
        TRADE HISTORY
      </Title>
      <Table
        className="w-100 mt-5"
        columns={columns}
        dataSource={data}
        scroll={{ x: 800 }}
        pagination={{
          current: page, // Current page
          pageSize: 10, // Number of items per page
          total: tPages, // Total number of items (from the server)
          onChange: (page) => {
            setPage(page); // Update the page state
            callBack({ page, showLoading: false }); // Fetch data for the new page
          },
        }}
        bordered
        style={{ padding: "0", margin: "0", textAlign: "center" }}
      />
    </div>
  );
};

export default UserTradeHistories;
