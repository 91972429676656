import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Trade from "./pages/main/trade";
import Activate from "./pages/main/activate";
import AddFunds from "./pages/main/addfunds";
import ConfirmID from "./pages/main/usdtconfirm";
import Withdraw from "./pages/main/withdraw";
import UserTransaction from "./pages/main/transaction";
import UserTradeHistory from "./pages/main/tradehx";
import Reg from "./pages/main/reg";
import Login from "./pages/main/login";
import Otp from "./pages/main/otp";
import ResetPwd from "./pages/main/rpwd";
import ResetPwdUser from "./pages/main/rpwduser";
import Error404 from "./pages/main/404";
import Adminlogin from "./pages/main/adminlogin";
import Admin from "./pages/main/admin/admin";
import Users from "./pages/main/admin/users";
import UserTransactions from "./pages/main/admin/usertransactions";
import UserNotification from "./pages/main/usernotification";
import Error403 from "./pages/main/403";
import EmailVerify from "./pages/main/email";
import {
  MenuOutlined,
  DollarOutlined,
  SwapOutlined,
  HistoryOutlined,
  LockOutlined,
  LogoutOutlined,
  BellOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Dropdown, Button, theme, Row, Col } from "antd";
import { Context } from "./AppContext";
import Preloader from "./comp/reuse/preloader";
import Cookies from "js-cookie";
import useGetPublicData from "./hooks/useGetPublicData";
import useGetPrivateData from "./hooks/useGetPrivateData";
import axios from "axios";
import Error500 from "./pages/main/500";
import CryptoJS from "crypto-js";
import usePost from "./hooks/usePost";
import { useEffect, useState } from "react";
import UserTradeHistories from "./pages/main/admin/usertrade";
import Floadingbtn from "./comp/reuse/floadingbtn";
import Logo from "./comp/reuse/logo";
const { Header, Content, Footer } = Layout;
function decryptData(encryptedData, iv) {
  try {
    // Ensure the secret key is defined
    const secretKey = process.env.REACT_APP_SECERETE_KEY.padEnd(32, "0");
    if (!secretKey) {
      throw new Error("Secret key is missing.");
    }

    // Decrypt the data using AES with the secret key and IV
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      CryptoJS.enc.Utf8.parse(secretKey),
      {
        iv: CryptoJS.enc.Hex.parse(iv), // Parse the IV from hex
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    // Convert the decrypted bytes to a UTF-8 string
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    if (!decrypted) {
      throw new Error("Decryption failed. The result is empty.");
    }

    return decrypted;
  } catch (error) {
    console.error("Error decrypting data:", error.message);
    return null;
  }
}
function encryptData(data) {
  const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
  const encrypted = CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECERETE_KEY.padEnd(32, "0")),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();

  return {
    encryptedData: encrypted,
    iv: iv.toString(CryptoJS.enc.Hex), // Convert IV to hex for storage/transmission
  };
}
function App() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const location = window.location.href;
  const pathname = window.location.pathname;

  const userToken = Cookies.get("user");
  const adminToken =
    window.location.href.includes("admin") &&
    !window.location.href.includes("admin-login") &&
    Cookies.get("admin");
  const { getData, loading } = useGetPrivateData();
  const { getPublicData } = useGetPublicData();
  const { postData } = usePost();
  const [user, setUser] = useState({});
  const [newUser, setNewUser] = useState({});
  const [app, setApp] = useState({});
  const [unread, setUnread] = useState();
  const [trade, setTrade] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [counry, setCountry] = useState("");
  const [isAppFetched, setIsAppFetched] = useState(false);
  const [isTradeFetched, setIsTradeFetched] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersPageData, setUsersPageData] = useState({});
  const [usersTransaction, setUsersTransaction] = useState([]);
  const [usersTransactionPageData, setUsersTransactionPageData] = useState({});
  const [usersTrade, setUsersTrade] = useState([]);
  const [usersTradePageData, setUsersTradePageData] = useState({});
  const [userTrade, setUserTrade] = useState([]);
  const [userTradePageData, setUserTradePageData] = useState({});
  const [userTransactionHx, setUserTransactionHx] = useState([]);
  const [userTransactionHxPageData, setUserTransactionHxPageData] = useState(
    {}
  );
  const [userNotification, setUserNotification] = useState([]);
  const [userNotificationPageData, setUserNotificationPageData] = useState({});
  const {
    setIsUser,
    isUser,
    app_data,
    setAppData,
    globalPrivateLoading,
    is500Error,
  } = Context();
  async function fetchUserData(params) {
    await getData(
      "user",
      async (d) => {
        const { isVerified, countryName } = d;
        setIsUser(isVerified);
        setUser(d);
        setCountry(countryName);
      },
      false
    );
  }
  async function fetchTrade(params) {
    userToken
      ? await getData(
          "trade-user",
          (d) => {
            const { encryptedData, iv } = d;
            const data = decryptData(encryptedData, iv);
            setTrade(JSON.parse(data));
            setIsTradeFetched(true);
          },
          false
        )
      : await getPublicData(
          "trade",
          (d) => {
            const { encryptedData, iv } = d;
            const data = decryptData(encryptedData, iv);
            setTrade(JSON.parse(data));
            setIsTradeFetched(true);
          },
          false
        );
  }
  async function fetchCountry(params) {
    let country_name = Cookies.get("country_name");
    let country_code = Cookies.get("country_code");
    if (!country_name || !country_code) {
      const result = await axios.get("https://ipapi.co/json");
      const { country_name: name, country_code: code } = result.data;
      country_name = name;
      country_code = code;
      Cookies.set("country_name", country_name, { expires: 30 });
      Cookies.set("country_code", country_code, { expires: 30 });
    }
    return { country_code, country_name };
  }
  async function fetchUnreadNot(params) {
    await getData(
      "notification-unread",
      (d) => {
        setUnread(d);
      },
      false
    );
  }
  async function fetAppData(params) {
    await getPublicData(
      "app",
      async (d) => {
        const { encryptedData, iv } = d;
        const data = decryptData(encryptedData, iv);
        const parsedData = JSON.parse(data);
        const { favico, company } = parsedData;
        setApp(parsedData);
        setAppData(parsedData);
        const link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = favico;
        document.getElementsByTagName("head")[0].appendChild(link);
        document.title = company + " | " + "Happy Trading!";
        if (userToken) {
          if (!location.includes("admin")) {
            if (pathname === "/") {
              await fetchUnreadNot();
            }
            await fetchUserData();
          }
        } else {
          const { country_code, country_name } = await fetchCountry();
          setCountry(country_name);
          setNewUser({
            bal: 0,
            countryCode: country_code,
            countryName: country_name,
            name: "Trader",
          });
        }
        if (pathname === "/") {
          await fetchTrade();
        }
        {
          setIsLoading(false);
        }
        setIsAppFetched(true);
      },
      false,
      () => {
        setIsLoading(false);
      }
    );
  }
  async function fetchUsers(page, isLoading = true) {
    await getData(
      "admin-users?page=" + page,
      (d) => {
        const { data, totalPages, currentPage } = d;
        setUsers(data);
        setUsersPageData({ totalPages, currentPage });
      },
      isLoading
    );
  }
  async function fetchUsersTransactionHistory(page, isLoading = true) {
    await getData(
      "admin-transaction?page=" + page,
      (d) => {
        const { data, currentPage, totalPages } = d;
        setUsersTransaction(data);
        setUsersTransactionPageData({ currentPage, totalPages });
      },
      isLoading
    );
  }
  async function fetchUsersTradeHistory(page, isLoading = true) {
    await getData(
      "admin-trade?page=" + page,
      (d) => {
        const { data, currentPage, totalPages } = d;
        setUsersTrade(data);
        setUsersTradePageData({ currentPage, totalPages });
      },
      isLoading
    );
  }
  async function fetchUserTransactionHistory(page, isLoading = true) {
    await getData(
      "transaction?page=" + page,
      (d) => {
        const { data, currentPage, totalPages } = d;
        setUserTransactionHx(data);
        setUserTransactionHxPageData({ currentPage, totalPages });
      },
      isLoading
    );
  }
  async function fetchUserTradeHistory(page, isLoading = true) {
    await getData(
      "user-trade-hx?page=" + page,
      (d) => {
        const { data, currentPage, totalPages } = d;
        setUserTrade(data);
        setUserTradePageData({ currentPage, totalPages });
      },
      isLoading
    );
  }
  async function fetchNotification(page, isLoading = true) {
    await getData(
      "notification-user?page=" + page,
      (d) => {
        const { data, currentPage, totalPages } = d;
        setUserNotification(data);
        setUserNotificationPageData({ currentPage, totalPages });
      },
      isLoading
    );
  }
  useEffect(() => {
    fetAppData();
  }, []);
  const sendLoginMessage = async () => {
    let name;
    if (isUser) {
      const { name: username } = user;
      name = username;
    }
    const data = { name, country: counry, url: location };
    const encryptedData = encryptData(JSON.stringify(data));

    await postData("location", { data: encryptedData }, () => null, false);
  };
  useEffect(() => {
    if (isAppFetched) {
      if (counry) {
        if (!location.includes("admin")) {
          sendLoginMessage();
        }
      }
    }
  }, [location, user, counry, isAppFetched]);
  const { company, logo, phone, colora, colorb } = app_data;

  const handleMenuClick = (e) => {
    if (adminToken) {
      switch (e.key) {
        case "1":
          window.location.href = "/admin";
          break;
        case "2":
          window.location.href = "/admin/trade";
          break;
        case "3":
          window.location.href = "/admin/transaction";
          break;
        case "4":
          Cookies.remove("admin");
          window.location.href = "/";
          break;

        default:
          break;
      }
    } else {
      switch (e.key) {
        case "1":
          window.location.href = "/deposit";
          break;
        case "2":
          window.location.href = "/withdraw";
          break;
        case "3":
          window.location.href = "/trade";
          break;
        case "4":
          window.location.href = "/transaction";
          break;
        case "5":
          window.location.href = "/notification";
          break;
        case "6":
          window.location.href = "/reset-password";
          break;
        case "7":
          Cookies.remove("user");
          window.location.href = "/login";
          break;
        default:
          break;
      }
    }
  };

  const menu = adminToken ? (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<HomeOutlined />}>
        Home
      </Menu.Item>
      <Menu.Item key="2" icon={<HistoryOutlined />}>
        Trade History
      </Menu.Item>
      <Menu.Item key="3" icon={<HistoryOutlined />}>
        Transaction History
      </Menu.Item>
      <Menu.Item key="4" icon={<LogoutOutlined />}>
        Log Out
      </Menu.Item>
    </Menu>
  ) : (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<DollarOutlined />}>
        Deposit
      </Menu.Item>
      <Menu.Item key="2" icon={<SwapOutlined />}>
        Withdrawal
      </Menu.Item>
      <Menu.Item key="3" icon={<HistoryOutlined />}>
        Trade History
      </Menu.Item>
      <Menu.Item key="4" icon={<HistoryOutlined />}>
        Transaction History
      </Menu.Item>
      <Menu.Item key="5" icon={<BellOutlined />}>
        Notiffication
      </Menu.Item>
      <Menu.Item key="6" icon={<LockOutlined />}>
        Reset Password
      </Menu.Item>
      <Menu.Item key="7" icon={<LogoutOutlined />}>
        Log Out
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    if (pathname === "/admin") {
      fetchUsers(1);
    }
    if (pathname === "/admin/transaction") {
      fetchUsersTransactionHistory(1);
    }
    if (pathname === "/admin/trade") {
      fetchUsersTradeHistory(1);
    }
    if (pathname === "/trade") {
      fetchUserTradeHistory(1);
    }
    if (pathname === "/transaction") {
      fetchUserTransactionHistory(1);
    }
    if (pathname === "/notification") {
      fetchNotification(1);
    }
  }, [pathname]);

  return globalPrivateLoading || isLoading ? (
    <Preloader />
  ) : is500Error ? (
    <Error500 />
  ) : (
    <div className="App">
      <Router>
        <Row
          style={{
            height: "100%",
            backgroundColor: colorBgContainer,
            position: "fixed",
            width: "100%",
          }}
          gutter={[0, 0]}
        >
          <Col
            xs={24}
            md={12}
            lg={10}
            style={{ overflowY: "scroll", height: "100%" }}
          >
            <Layout
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Header
                style={{
                  padding: 0,
                  background: colorBgContainer,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <a
                    style={{
                      height: 80,
                      width: 120,
                      marginLeft: "8px",
                      // marginTop: 10,
                    }}
                    href="/"
                    className="d-flex align-items-center"
                  >
                    <img
                      src={'/logo.png'}
                      loading="lazy"
                      className="img-fluid mt-2"
                      alt="logo"
                      style={{
                        width: "100%",
                        marginRight: "10px",
                      }}
                    />
                  </a>
                </div>
                {(isUser || adminToken) && (
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button
                      type="text"
                      icon={<MenuOutlined />}
                      style={{ marginRight: "16px" }}
                    />
                  </Dropdown>
                )}
              </Header>
              <Content
                style={{
                  margin: "10px 16px",
                  flex: "1 0 auto",
                }}
              >
                <div
                  style={{
                    minHeight: "100%",
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                  className="px-3"
                >
                  <Row className="d-flex justify-content-center">
                    <Col xs={24} sm={17} md={24}>
                      <div className="mt-5 w-100">
                        <Routes>
                          <Route
                            path="/"
                            element={
                              !isTradeFetched && !isLoading ? (
                                <Error500 />
                              ) : (
                                <Trade
                                  notCount={unread}
                                  user={isUser ? user : newUser}
                                  trade={trade}
                                />
                              )
                            }
                          />
                          {isUser && (
                            <>
                              <Route
                                path="/activate"
                                element={<Activate user={user} />}
                              />
                              <Route
                                path="/deposit"
                                element={<AddFunds app={app} />}
                              />
                              <Route
                                path="/confirm-usdt"
                                element={<ConfirmID />}
                              />
                              <Route
                                path="/withdraw"
                                element={<Withdraw user={user} />}
                              />
                              <Route
                                path="/transaction"
                                element={
                                  <UserTransaction
                                    pageData={userTransactionHxPageData}
                                    dbData={userTransactionHx}
                                    callBack={async (d) => {
                                      const { page, showLoading } = d;
                                      await fetchUserTransactionHistory(
                                        page,
                                        showLoading
                                      );
                                    }}
                                  />
                                }
                              />
                              <Route
                                path="/trade"
                                element={
                                  <UserTradeHistory
                                    pageData={userTradePageData}
                                    dbData={userTrade}
                                    callBack={async (d) => {
                                      const { page, showLoading } = d;
                                      await fetchUserTradeHistory(
                                        page,
                                        showLoading
                                      );
                                    }}
                                  />
                                }
                              />
                              <Route
                                path="/notification"
                                element={
                                  <UserNotification
                                    dbData={userNotification}
                                    pageData={userNotificationPageData}
                                    callBack={async (d) => {
                                      const { page, showLoading } = d;
                                      await fetchNotification(
                                        page,
                                        showLoading
                                      );
                                      await fetchUnreadNot(showLoading);
                                    }}
                                  />
                                }
                              />
                            </>
                          )}
                          <Route path="/register" element={<Reg />} />
                          <Route path="/login" element={<Login />} />
                          <Route path="/verify-email" element={<Otp />} />
                          <Route path="/email" element={<EmailVerify />} />
                          <Route
                            path="/reset-password-verify"
                            element={<ResetPwd />}
                          />
                          <Route
                            path="/reset-password"
                            element={<ResetPwdUser />}
                          />
                          <Route path="/*" element={<Error404 />} />
                          <Route path="/error" element={<Error500 />} />
                          <Route path="/terms" element={<Error500 />} />
                          <Route path="/conditions" element={<Error500 />} />
                          <Route path="/login-admin" element={<Adminlogin />} />
                          <Route path="/admin" element={<Admin />}>
                            <Route
                              path="/admin"
                              element={
                                <Users
                                  users={users}
                                  callBack={async (d) => {
                                    const { page, showLoading } = d;
                                    await fetchUsers(page, showLoading);
                                  }}
                                  pageData={usersPageData}
                                />
                              }
                            />
                            <Route
                              path="/admin/transaction"
                              element={
                                <UserTransactions
                                  history={usersTransaction}
                                  callBack={async (d) => {
                                    const { page, showLoading } = d;
                                    await fetchUsersTransactionHistory(
                                      page,
                                      showLoading
                                    );
                                  }}
                                  pageData={usersTransactionPageData}
                                />
                              }
                            />
                            <Route
                              path="/admin/trade"
                              element={
                                <UserTradeHistories
                                  history={usersTrade}
                                  callBack={async (d) => {
                                    const { page, showLoading } = d;
                                    await fetchUsersTradeHistory(
                                      page,
                                      showLoading
                                    );
                                  }}
                                  pageData={usersTradePageData}
                                />
                              }
                            />
                          </Route>
                        </Routes>
                      </div>
                    </Col>
                    {pathname === "/" && (
                      <Floadingbtn phone={phone} isUserVerified={isUser} />
                    )}
                  </Row>
                </div>
              </Content>
              <Footer
                style={{
                  textAlign: "center",
                  flexShrink: 0,
                  background: colorBgContainer,
                }}
                className="p-2"
              >
                {company} ©{new Date().getFullYear()}. All rights reserved.
              </Footer>
            </Layout>
          </Col>
          <Col xs={0} sm={0} md={12} lg={14} style={{ height: "100%" }}>
            <Layout style={{ height: "100%", width: "100%" }}>
              <Content
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage:
                      "url('/asset/imgs/code-symbols-programming-hjbyni9117oaqba7.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Content>
            </Layout>
          </Col>
        </Row>
      </Router>
    </div>
  );
}

export default App;
