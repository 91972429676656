import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Badge,
  Dropdown,
  Menu,
  Typography,
  Space,
  Modal,
} from "antd";
import {
  DeleteOutlined,
  EyeOutlined,
  FilterOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import useGetPrivateData from "../../hooks/useGetPrivateData";
import usePostPrivate from "./../../hooks/usePostPrivate";
import { Context } from "../../AppContext";
import Myalert from "../../comp/reuse/alert";

const { Title } = Typography;

const UserNotification = ({
  pageData = {},
  dbData = [],
  callBack = async () => null,
}) => {
  const [dataSource, setDataSource] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");
  const { getData } = useGetPrivateData();
  const { postData, message } = usePostPrivate();
  const { totalPages, currentPage } = pageData;
  const { modalAction } = Context();
  const [tPages, setTPages] = useState();
  const [page, setPage] = useState(1);

  async function fetchNotification(showLoading = true) {
    await getData(
      "notification-user?page=" + page,
      (d) => {
        setDataSource(d.data?.reverse());
      },
      showLoading
    );
  }
  useEffect(() => {
    setPage(currentPage);
    setTPages(totalPages);
  }, [pageData]);

  const handleRead = async (record) => {
    setCurrentMessage(record.message);
    setCurrentTitle(record.title);
    setIsModalVisible(true);
    if (record.status === "unread") {
      await getData(
        "notification-read?id=" + record.id,
        async () => {
          const data = {
            showLoading: false,
            page,
          };
          await callBack({ ...data });
        },
        false
      );
    }
  };

  useEffect(() => {
    setDataSource(dbData);
  }, [dbData]);
  const handleDelete = (id) => {
    modalAction(
      "error",
      "Are you sure you want to delete this notification message?",
      async () =>
        await postData("notification-delete?id=" + id, {}, async (d) => {
          const data = {
            showLoading: false,
            page,
          };
          await callBack({ ...data });
        }),
      "CONFIRM",
      "danger"
    );
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      filters: [
        { text: "Read", value: "read" },
        { text: "Unread", value: "unread" },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Badge status={status === "read" ? "success" : "error"} text={status} />
      ),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (_, record) => (
        <Space size="small">
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => handleRead(record)}
          ></Button>
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
          ></Button>
        </Space>
      ),
      align: "center",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
      ellipsis: true,
      align: "center",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (message) => (
        <div dangerouslySetInnerHTML={{ __html: message }} />
      ),
      width: 300,
      ellipsis: true,
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 200,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      align: "center",
    },
  ];

  return (
    <div className="w-100 text-center">
      <Title className="m-0 text-center" level={4}>
        Notifications
      </Title>
      <div className="my-5">
        {message && (
          <Myalert showIcon={true} type={message?.type} desc={message?.msg} />
        )}
      </div>
      <Table
        className="w-100 mt-5"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          current: page, // Current page
          pageSize: 10, // Number of items per page
          total: tPages, // Total number of items (from the server)
          onChange: async (page) => {
            setPage(page); // Update the page state
            await callBack({ page, showLoading: false }); // Fetch data for the new page
          },
        }}
        bordered
        scroll={{ x: 800 }}
        style={{ padding: "0", margin: "0", textAlign: "center" }}
      />
      <Modal
        title={currentTitle}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
      >
        <div dangerouslySetInnerHTML={{ __html: currentMessage }} />
      </Modal>
    </div>
  );
};

export default UserNotification;
