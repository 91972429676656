import React, { useContext, useState } from "react";
import { Typography, Input, Button, message, Select, Radio, Alert } from "antd";
import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Gradientbutton from "../../comp/reuse/gradientbutton";
import Mtooltip from "../../comp/reuse/tooltip";
import Myalert from "../../comp/reuse/alert";
import { Context } from "../../AppContext";
import usePostPrivate from "../../hooks/usePostPrivate";
import useGetPrivateData from "../../hooks/useGetPrivateData";

const { Title, Text } = Typography;
const durationOptions = [
  {
    value: "24hrs",
    label: "24hrs",
  },
  {
    value: "48hrs",
    label: "48hrs",
  },
  {
    value: "72hrs",
    label: "72hrs",
  },
  {
    value: "1week",
    label: "1week",
  },
];
const typeOptions = [
  {
    label: "Simple",
    value: "simple",
  },
  {
    label: "Compound",
    value: "compound",
  },
];

const Activate = ({ user }) => {
  const { bal: amount } = user;
  const { modalAction } = Context();
  const [inputValue, setInputValue] = useState("");
  const [durationValue, setDurationValue] = useState(durationOptions[0].value);
  const [typeValue, setTypeValue] = useState(typeOptions[0].value);
  const [errorMessage, setErrorMessage] = useState("");
  const { getData } = useGetPrivateData();
  const handleGetMax = () => {
    const maxAmount = amount; // Assuming user.amount holds the maximum amount
    setInputValue(maxAmount);
    if (maxAmount < 100) {
      setErrorMessage("Amount must be at least 100 USD.");
    } else {
      setErrorMessage("");
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      setErrorMessage("Please enter a valid number.");
    } else {
      setInputValue(value);
      if (value < 100) {
        setErrorMessage("Amount must be at least 100 USD.");
      } else {
        setErrorMessage("");
      }
    }
  };
  const { postData, message } = usePostPrivate();
  const handleTrade = async () => {
    const data = {
      amount: Number(inputValue),
      duration: durationValue,
      type: typeValue,
    };
    await postData("addtrade", data, () => {});
  };
  async function handleConfirm(params) {
    await getData(
      `price-duration?duration=${durationValue}`,
      (d) => {
        const reward = (
          d * 0.1 * Number(inputValue) +
          Number(inputValue)
        ).toFixed(2);
        modalAction(
          "warning",
          `After ${durationValue}, if trade ends in for example 10%, your total reward will be ${reward} USD`,
          async () => await handleTrade(),
          "CONFIRM",
          "success"
        );
      },
      false
    );
  }

  return (
    <div className="w-100">
      <Title className="m-0 text-center" level={4}>
        ACTIVATE TRADE
      </Title>
      <div className="mt-4">
        <Myalert
          title={""}
          desc={
            "We guarantee 100% of your investment if your trade ends in loss."
          }
        />
      </div>
      <div className="" style={{ marginTop: 30 }}>
        <div
          className="input-copy-container "
          style={{
            maxWidth: 300,
            minWidth: 200,
            marginTop: 16,
            borderRadius: 8,
          }}
        >
          <Title level={5}>
            Enter Amount:{" "}
            <Mtooltip title={"How much you're willing to invest."} />
          </Title>
          <Input
            value={inputValue}
            required
            onChange={handleInputChange}
            suffix={
              <Button
                onClick={handleGetMax}
                type="default"
                variant="outlined"
                size="small"
                style={{}}
              >
                MAX
              </Button>
            }
            style={{
              borderRadius: "4px",
              border: "1px solid #d9d9d9",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
          {errorMessage && (
            <Text type="danger" className="d-flex align-items-center">
              <InfoCircleOutlined style={{ marginRight: 4, fontSize: 12 }} />
              {errorMessage}
            </Text>
          )}
        </div>
        <div
          className="input-copy-container "
          style={{
            maxWidth: 300,
            minWidth: 200,
            marginTop: 16,
            borderRadius: 8,
          }}
        >
          <Title level={5}>
            Choose duration:{" "}
            <Mtooltip title={"How long you want to hold the trade."} />
          </Title>
          <Select
            defaultValue={durationOptions[0].value}
            value={durationValue}
            onChange={(value) => setDurationValue(value)}
            options={durationOptions}
            style={{
              width: 120,
              borderRadius: "4px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </div>
        <div
          className="input-copy-container "
          style={{
            maxWidth: 300,
            minWidth: 200,
            marginTop: 16,
            borderRadius: 8,
          }}
        >
          <Title level={5}>
            Choose type:{" "}
            <Mtooltip
              title={`Choosing "simple" ends the trade after the selected duration.`}
            />
          </Title>
          <Radio.Group
            block
            options={typeOptions}
            defaultValue={typeOptions[0].value}
            value={typeValue}
            onChange={(e) => setTypeValue(e.target.value)}
          />
        </div>

        <div className="mt-4">
          <Alert
            title={""}
            closable={true}
            showIcon={false}
            description={
              <>
                <Text>
                  Selecting <strong>simple</strong> means the trade ends after
                  the selected duration.
                </Text>
                <div className="mt-2">
                  <Text className="">
                    Selecting <strong>compound</strong> means the trade
                    automatically reactivates with the total earnings after the
                    selected duration until it is manually stopped.
                  </Text>
                </div>
              </>
            }
          />
        </div>
        <div className="mt-3">
          {message && (
            <Myalert
              title={""}
              closable={false}
              showIcon={false}
              type={message?.type}
              desc={message?.msg}
            />
          )}
        </div>
        <Gradientbutton
          btntext="ENTER TRADE"
          mClasses="mt-5 d-flex align-items-center justify-content-end w-100"
          size="large"
          callBack={handleConfirm}
          disabled={!inputValue || inputValue < 100}
        />
      </div>
    </div>
  );
};

export default Activate;
