import React, { useState, useContext, useEffect, useCallback } from "react";
import { Table, Typography, Button, Input, Form } from "antd";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { Context } from "../../../AppContext";
import useGetPrivateData from "../../../hooks/useGetPrivateData";
import usePostPrivate from "../../../hooks/usePostPrivate";

const { Title } = Typography;

const Users = ({ pageData = {}, users = [], callBack = async () => null }) => {
  const { modalAction } = Context();
  const { currentPage, totalPages } = pageData;
  const [page, setPage] = useState();
  const [tPages, setTPages] = useState();
  const [dataSource, setDataSource] = useState([]);
  const { postData } = usePostPrivate();

  useEffect(() => {
    setDataSource(users);
  }, [users]);
  async function handleDeleteUser(id) {
    await postData("admin-users?id=" + id, {}, async (d) => {
      await callBack({ page, showLoading: false });
    });
  }
  useEffect(() => {
    setPage(currentPage);
    setTPages(totalPages);
  }, [pageData]);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      render: (text) => <a>{text}</a>,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      align: "center",
    },
    {
      title: "Balance ($)",
      dataIndex: "bal",
      key: "bal",
      width: 150,
      editable: true,
      align: "center",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 150,
      align: "center",
    },
    {
      title: "Joined",
      dataIndex: "joined",
      key: "joined",
      width: 500,
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <Button
            style={{ marginLeft: 5 }}
            onClick={async () =>
              modalAction(
                "warning",
                `Are you sure you want to delete ${record?.name}?`,
                async () => await handleDeleteUser(record._id)
              )
            }
            type="primary"
            danger
            icon={<DeleteOutlined />}
          />
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <div className="w-100 text-center">
      <Title level={4}>USERS</Title>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          current: page, // Current page
          pageSize: 10, // Number of items per page
          total: tPages, // Total number of items (from the server)
          onChange: (page) => {
            setPage(page); // Update the page state
            callBack({ page, showLoading: false }); // Fetch data for the new page
          },
        }}
        scroll={{ x: 800 }}
        bordered
      />
    </div>
  );
};

export default Users;
