import { Button, ConfigProvider } from "antd";
import { createStyles } from "antd-style";
import React from "react";
import { Context } from "../../AppContext";
const useStyle = createStyles(({ prefixCls, css }) => ({
  linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(
        .${prefixCls}-btn-dangerous
      ) {
      > span {
        position: relative;
      }

      &::before {
        content: "";
        background: linear-gradient(90deg, #1890ff, #ff4d4f);
        position: absolute;
        inset: -1px;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
}));
const Gradientbutton = ({
  size = "default",
  mClasses = "",
  callBack = () => {},
  btntext = "",
  type = false,
  disabled = false,
}) => {
  const { styles } = useStyle();
  const { globalLoadBtn } = Context();

  return (
    <ConfigProvider
      button={{
        className: styles.linearGradientButton,
      }}
    >
      <div className={`${mClasses}`}>
        <Button
          disabled={globalLoadBtn || disabled}
          onClick={callBack}
          type="primary"
          size={size}
          loading={globalLoadBtn}
          htmlType={type ? "submit" : "button"}
        >
          {btntext}
        </Button>
      </div>
    </ConfigProvider>
  );
};

export default Gradientbutton;
