import React, { useState } from "react";
import { axiosPuplic } from "../api";
import { Context } from "../AppContext";

const useGetPublicData = () => {
  const [loading, setLoading] = useState(false);
  const { setIs500Error, setGLobalPrivateLoading } = Context();
  async function getPublicData(
    url,
    successFunction = async () => null,
    showLoading = true,
    callBack = () => null
  ) {
    setLoading(true);
    setGLobalPrivateLoading(showLoading);
    try {
      const response = await axiosPuplic.get(url);
      await successFunction(response.data);
      setIs500Error(false);
      setLoading(false);
      setGLobalPrivateLoading(false);
    } catch (error) {
      if (error.response?.status === 500) {
        setIs500Error(true);
        setLoading(false);
        setGLobalPrivateLoading(false);
      }
    } finally {
      setLoading(false);
      setGLobalPrivateLoading(false);
      callBack();
    }
  }
  return { getPublicData, loading };
};

export default useGetPublicData;
