import React, { useState, useEffect } from "react";
import { Typography, Input, Button, Form, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import Gradientbutton from "../../comp/reuse/gradientbutton";
import Myalert from "../../comp/reuse/alert";
import usePostPrivate from "./../../hooks/usePostPrivate";
import Cookies from "js-cookie";

const { Title, Text } = Typography;

const ResetPwdUser = () => {
  const [password, setPassword] = useState("");
  const [passwordStatus, setPasswordStatus] = useState({
    length: false,
    number: false,
    specialChar: false,
  });
  const { postData, message } = usePostPrivate();
  useEffect(() => {
    const length = password.length >= 6;
    const number = /\d/.test(password);
    const specialChar = /[!@#$%^&*]/.test(password);

    setPasswordStatus({ length, number, specialChar });
  }, [password]);

  const validatePassword = (rule, value) => {
    if (!value) {
      return Promise.reject("Password is required");
    }
    if (
      !passwordStatus.length ||
      !passwordStatus.number ||
      !passwordStatus.specialChar
    ) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    const { password } = values;
    await postData("reset-password-user", { password }, () => {
      Cookies.remove("user");
      window.location.href = "/login";
    });
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Please check the form and try again.");
  };

  return (
    <div className="w-100">
      <Title className="m-0 text-center" level={4}>
        RESET PASSWORD
      </Title>
      <div className="mt-4">
        {message && (
          <Myalert
            showIcon={false}
            title={""}
            type={message?.type}
            desc={message?.msg}
          />
        )}
        <Form
          name="resetPassword"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          size="large"
          style={{ marginTop: "24px" }}
        >
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              { required: true, message: "Please input your old password!" },
            ]}
            hasFeedback
            validateTrigger="onBlur"
            style={{ marginBottom: 24 }}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="password"
            rules={[{ validator: validatePassword }]}
            hasFeedback
            validateTrigger="onBlur"
            style={{ marginBottom: 24 }}
          >
            <Input.Password
              prefix={<LockOutlined />}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.password !== currentValues.password
            }
            noStyle
          >
            {({ getFieldValue, isFieldTouched }) =>
              isFieldTouched("password") && (
                <div style={{ marginBottom: 24 }}>
                  <span>
                    <span
                      style={{ color: passwordStatus.length ? "green" : "red" }}
                    >
                      Password must be at least 6 characters long
                    </span>
                    ,{" "}
                    <span
                      style={{ color: passwordStatus.number ? "green" : "red" }}
                    >
                      contain at least one number
                    </span>
                    ,{" "}
                    <span
                      style={{
                        color: passwordStatus.specialChar ? "green" : "red",
                      }}
                    >
                      and contain at least one special character
                    </span>
                    .
                  </span>
                </div>
              )
            }
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            validateTrigger="onBlur"
            style={{ marginBottom: 24 }}
            rules={[
              { required: true, message: "Please confirm your new password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item>
            <Gradientbutton type={true} btntext="RESET PASSWORD" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPwdUser;
