import React, { useEffect, useState } from "react";
import { Space, Table, Tag, Typography } from "antd";
import useGetPrivateData from "./../../hooks/useGetPrivateData";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 60,
    render: (text) => <a>{text}</a>,
    onCell: () => ({
      style: { padding: 8, textAlign: "center" },
    }),
    align: "center",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 80,
    render: (amount) => `$${amount}`,
    onCell: () => ({
      style: { padding: 8, textAlign: "center" },
    }),
    align: "center",
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    width: 120,
    sorter: (a, b) => new Date(a.time) - new Date(b.time),
    onCell: () => ({
      style: { padding: 8, textAlign: "center" },
    }),
    align: "center",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: 80,
    filters: [
      { text: "Deposit", value: "deposit" },
      { text: "Withdraw", value: "withdraw" },
      { text: "Referral", value: "referral" },
      { text: "Trade", value: "trade" },
      { text: "Trade Fee", value: "trade fee" },
    ],
    onFilter: (value, record) => record.type.includes(value),
    onCell: () => ({
      style: { padding: 8, textAlign: "center" },
    }),
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 80,
    sorter: (a, b) => a.status.localeCompare(b.status),
    filters: [
      { text: "Pending", value: "pending" },
      { text: "Declined", value: "declined" },
      { text: "Completed", value: "completed" },
    ],
    onFilter: (value, record) => record.status.includes(value),
    onCell: () => ({
      style: { padding: 8, textAlign: "center" },
    }),
    align: "center",
  },
];

const { Title } = Typography;

const UserTransaction = ({
  pageData = {},
  dbData = [],
  callBack = async () => null,
}) => {
  const { totalPages, currentPage } = pageData;
  const [data, setData] = useState([]);
  const [tPages, setTPages] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(currentPage);
    setTPages(totalPages);
  }, [pageData]);
  useEffect(() => {
    setData(dbData);
  }, [dbData]);

  return (
    <div className="w-100 text-center">
      <Title className="m-0 text-center" level={4}>
        TRANSACTION HISTORY
      </Title>
      <Table
        className="w-100 mt-5"
        columns={columns}
        dataSource={data}
        scroll={{ x: 800 }}
        pagination={{
          current: page, // Current page
          pageSize: 10, // Number of items per page
          total: tPages, // Total number of items (from the server)
          onChange: async (page) => {
            setPage(page); // Update the page state
            await callBack({ page, showLoading: false }); // Fetch data for the new page
          },
        }}
        bordered
        style={{ padding: "0", margin: "0", textAlign: "center" }}
      />
    </div>
  );
};

export default UserTransaction;
