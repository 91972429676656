import React, { useContext, useState } from "react";
import { Typography, Input, Button, message } from "antd";
import {
  CopyOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Gradientbutton from "../../comp/reuse/gradientbutton";
import Mtooltip from "../../comp/reuse/tooltip";
import Myalert from "../../comp/reuse/alert";
import { Context } from "../../AppContext";
import USDT from "./comp/modals/usdt";
import usePostPrivate from "../../hooks/usePostPrivate";

const { Title, Text } = Typography;

const Withdraw = ({ user }) => {
  const { bal: amount } = user;
  const { modalAction } = Context();
  const [inputValue, setInputValue] = useState("");
  const [userWallet, setUserWallet] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { postData, message: dbMessage } = usePostPrivate();

  const handleWithdrawFunds = async () => {
    if (isNaN(inputValue)) {
      setErrorMessage("Please enter a valid number.");
      return;
    }
    if (inputValue < 100) {
      setErrorMessage("Amount must be at least 100 USD.");
      return;
    }
    const data = {
      amount: Number(inputValue),
      walletAddress: userWallet,
    };
    await postData("withdrawFunds", data, () => {});
  };

  const handlePaste = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        setUserWallet(text);
        message.success("Pasted from clipboard!");
      })
      .catch((err) => {
        message.error("Failed to read clipboard contents.");
      });
  };

  const handleGetMax = () => {
    setInputValue(amount);
    if (amount < 100) {
      setErrorMessage("Amount must be at least 100 USD.");
    } else {
      setErrorMessage("");
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      setErrorMessage("Please enter a valid number.");
    } else {
      setInputValue(value);
      if (value < 100) {
        setErrorMessage("Amount must be at least 100 USD.");
      } else {
        setErrorMessage("");
      }
    }
  };

  return (
    <div className="w-100">
      <Title className="m-0 text-center" level={4}>
        WITHDRAW FUNDS
      </Title>
      <div className="mt-4">
        <Myalert
          title={""}
          desc={`Your last deposit method was via crypto (USDT). We've suggested USDT withdrawal method for you.`}
        />
      </div>

      <div
        className="input-copy-container"
        style={{
          maxWidth: 300,
          minWidth: 200,
          marginTop: 16,
          borderRadius: 8,
        }}
      >
        <Title level={5}>
          Enter Amount:{" "}
          <Mtooltip
            title={"How much you want to withdraw. You've $" + amount}
          />
        </Title>
        <Input
          value={inputValue}
          required
          onChange={handleInputChange}
          suffix={
            <Button
              onClick={handleGetMax}
              type="default"
              variant="outlined"
              size="small"
              style={{}}
            >
              MAX
            </Button>
          }
          style={{
            borderRadius: "4px",
            border: "1px solid #d9d9d9",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
        {errorMessage && (
          <Text type="danger" className="d-flex align-items-center">
            <InfoCircleOutlined style={{ marginRight: 4, fontSize: 12 }} />
            {errorMessage}
          </Text>
        )}
      </div>
      <div className="" style={{ marginTop: 30 }}>
        <div
          className="input-copy-container"
          style={{
            maxWidth: 300,
            minWidth: 200,
            marginTop: 16,
            borderRadius: 8,
          }}
        >
          <Title level={5}>
            ENTER YOUR WALLET ADDRESS:{" "}
            <Mtooltip
              title={"Copy your TRC (USDT) wallet address and paste here."}
            />
          </Title>
          <Input
            value={userWallet}
            required
            onChange={(e) => setUserWallet(e.target.value)}
            style={{
              borderRadius: "4px",
              border: "1px solid #d9d9d9",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
            suffix={
              <Button
                icon={<FileTextOutlined />}
                onClick={handlePaste}
                type="default"
                variant="outlined"
                size="small"
                style={{ borderRadius: 4 }}
              >
                PASTE
              </Button>
            }
          />
        </div>
        <div className="mt-4">
          {dbMessage && (
            <Myalert
              title={""}
              showIcon={true}
              desc={dbMessage?.msg}
              type={dbMessage?.type}
            />
          )}
        </div>
        <Gradientbutton
          btntext="WITHDRAW"
          mClasses="mt-5 d-flex align-items-center justify-content-end w-100"
          size="large"
          callBack={handleWithdrawFunds}
          disabled={!inputValue || !userWallet || inputValue < 100}
        />
      </div>
    </div>
  );
};

export default Withdraw;
