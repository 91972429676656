import axios from "axios";
import { domain } from "./util";
import Cookies from "js-cookie";
import axiosRetry from "axios-retry";
const location =
  window.location.href.includes("admin") &&
  !window.location.href.includes("admin-login");
const token = location ? Cookies.get("admin") : Cookies.get("user");
export const axiosPuplic = axios.create({
  baseURL: domain,
  headers: {
    "Content-Type": "application/json",
  },
});
export const axiosPrivate = axios.create({
  baseURL: domain,
  headers: {
    "Content-Type": "application/json",
    "access-token": token,
  },
});
export const axiosFormPrivate = axios.create({
  baseURL: domain,
  headers: {
    "Content-Type": "multipart/form-data",
    "access-token": token,
  },
});
const applyAxiosRetry = (instance) => {
  axiosRetry(instance, {
    retries: 6,
    retryDelay: (retryCount) => retryCount * 1000, // 1 second delay between retries
    retryCondition: (error) => error?.response?.status === 500, // Retry on 500 status code
  });
};

applyAxiosRetry(axiosPuplic);
applyAxiosRetry(axiosPrivate);
applyAxiosRetry(axiosFormPrivate);
