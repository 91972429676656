import React, { useContext, useState } from "react";
import { Typography, Input, Radio, Badge } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Gradientbutton from "../../comp/reuse/gradientbutton";
import Mtooltip from "../../comp/reuse/tooltip";
import Myalert from "../../comp/reuse/alert";
import { Context } from "../../AppContext";
import USDT from "./comp/modals/usdt";

const { Title, Text } = Typography;

const typeOptions = [
  {
    label: "Bank Transfer",
    value: "Bank Transfer",
  },
  {
    label: "Card Deposit",
    value: "Card Deposit",
  },
  {
    label: (
      <>
        Crypto deposit (USDT){" "}
        <Badge
          count="recommended"
          style={{ backgroundColor: "gold", fontSize: 10 }}
        />
      </>
    ),
    value: "Cryto Deposit (USDT)",
  },
  {
    label: "Paypal",
    value: "Paypal",
  },
];

const AddFunds = ({ app }) => {
  const { wallet } = app;
  const { modalAction } = Context();
  const [inputValue, setInputValue] = useState("");
  const [typeValue, setTypeValue] = useState(typeOptions[2].value);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddFunds = () => {
    if (typeValue !== typeOptions[2].value) {
      modalAction(
        "error",
        `Error occurred selecting ${typeValue}. Choose a different method. Crypto deposit is recommended.`
      );
      return;
    }
    setOpen(true);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setInputValue(value);
      if (value < 100) {
        setErrorMessage("Amount must be at least 100 USD.");
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("Please enter a valid number.");
    }
  };

  return (
    <div className="w-100">
      <Title className="m-0 text-center" level={4}>
        ADD FUNDS
      </Title>
      <div className="" style={{ marginTop: 30 }}>
        <div
          className="input-copy-container"
          style={{
            maxWidth: 300,
            minWidth: 200,
            marginTop: 16,
            borderRadius: 8,
          }}
        >
          <Title level={5}>
            Enter Amount (USD):{" "}
            <Mtooltip title={"How much you want to deposit."} />
          </Title>
          <Input
            value={inputValue}
            required
            onChange={handleInputChange}
            style={{
              borderRadius: "4px",
              border: "1px solid #d9d9d9",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
          {errorMessage && (
            <Text type="danger" className="d-flex align-items-center">
              <InfoCircleOutlined style={{ marginRight: 4, fontSize: 12 }} />
              {errorMessage}
            </Text>
          )}
        </div>
        <div
          className="input-copy-container"
          style={{
            maxWidth: 300,
            minWidth: 200,
            marginTop: 16,
            borderRadius: 8,
          }}
        >
          <Title level={5}>Choose Method: </Title>
          <Radio.Group
            className="d-flex flex-column justify-content-start"
            options={typeOptions}
            defaultValue={typeOptions[2].value}
            value={typeValue}
            onChange={(e) => setTypeValue(e.target.value)}
          />
        </div>
        <Gradientbutton
          btntext="ADD FUNDS"
          mClasses="mt-5 d-flex align-items-center justify-content-end w-100"
          size="large"
          callBack={handleAddFunds}
          disabled={!inputValue || inputValue < 100}
        />
      </div>
      <USDT wallet={wallet} amount={inputValue} open={open} close={() => setOpen(false)} />
    </div>
  );
};

export default AddFunds;
