import React, { useState } from "react";
import { Typography, Input, Button, Form, message, Modal } from "antd";
import { MailOutlined } from "@ant-design/icons";
import Gradientbutton from "../../../../comp/reuse/gradientbutton";
import Myalert from "../../../../comp/reuse/alert";
import usePost from "../../../../hooks/usePost";

const { Title, Text } = Typography;

const ForgotPassword = ({ open = false, close = () => {} }) => {
  const { postData, message:dbMessage } = usePost();
  const onFinish = async (values) => {
    const { email } = values;
    await postData("resend?email=" + email+ "&rpw=" + true, {}, (d) => {
      window.location.href = "/verify-email?token=" + d + "&rpw=" + true;
    });
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Please check the form and try again.");
  };

  return (
    <Modal footer={null} open={open} onCancel={close}>
      <div className="w-100">
        <Title className="m-0 text-center" level={4}>
          FORGOT PASSWORD
        </Title>
        <div className="mt-4">
          {dbMessage && (
            <Myalert
              type={dbMessage?.type}
              showIcon={false}
              title={""}
              desc={dbMessage?.msg}
            />
          )}
          <div className="my-4">
            <Text className="">
              Remembered your password? <a href="/login">Login</a>
            </Text>
          </div>

          <Form
            name="forgotPassword"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            size="large"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
              style={{ marginBottom: 24 }}
            >
              <Input prefix={<MailOutlined />} />
            </Form.Item>
            <Form.Item>
              <Form.Item>
                <Gradientbutton type={true} btntext="PROCEED" />
              </Form.Item>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPassword;
