import React, { useState } from "react";
import { Context } from "../AppContext";
import { axiosPuplic } from "../api";
import { domain } from "../util";

const usePost = () => {
  const [message, setMessage] = useState("");
  const { setGlobalLoadBtn } = Context();
  async function postData(
    url,
    info,
    successFunction = () => null,
    showLoading = true
  ) {
    setMessage();
    try {
      setGlobalLoadBtn(showLoading);
      const response = await axiosPuplic.post(domain + url, info);
      setMessage({ type: "success", msg: response.data.msg });
      successFunction(response.data.data);
    } catch (error) {
      setMessage({
        type: "error",
        msg: error.response.data.msg,
        error_code: error.response.data.error_code,
        status: error.response.data.status,
      });
    } finally {
      setGlobalLoadBtn(false);
    }
  }
  return { postData, message };
};

export default usePost;
