import React, { useState } from "react";
import { Typography, Input, Button, Form, Checkbox, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import Gradientbutton from "../../comp/reuse/gradientbutton";
import ForgotPassword from "./comp/modals/forgotpassword";
import Myalert from "../../comp/reuse/alert";
import usePost from "./../../hooks/usePost";
import Cookies from "js-cookie";

const { Title, Text } = Typography;

const Login = () => {
  const [open, setOpen] = useState(false);
  const { postData, message: dbMessage } = usePost();
  const onFinish = async (values) => {
    const { email, password, } = values;
    await postData("login", { email, password }, (d) => {
      if (d?.isVerified) {
        Cookies.set("user", d.token, { expires: 30 });
        window.location.href = "/";
      } else {
        window.location.href = "/verify-email?token=" + d?.token;
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Please check the form and try again.");
  };

  return (
    <div className="w-100">
      <Title className="m-0 text-center" level={4}>
        LOGIN
      </Title>
      <div className="mt-4">
        {dbMessage && (
          <Myalert
            showIcon={false}
            title={""}
            desc={dbMessage?.msg}
            type={dbMessage?.type}
          />
        )}
        <div className="my-4">
          <Text className="">
            Don't have an account? <a href="/register">Create Account</a>
          </Text>
        </div>

        <Form
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          size="large"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
            style={{ marginBottom: 24 }}
          >
            <Input prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            style={{ marginBottom: 24 }}
            initialValues={{ remember: true }}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            style={{ marginBottom: 24 }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <Checkbox defaultChecked={true}>Remember me</Checkbox>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
                href="/forgot-password"
              >
                Forgot Password?
              </a>
            </div>
          </Form.Item>
          <Form.Item>
            <Gradientbutton type={true} btntext="LOGIN" />
          </Form.Item>
          <Form.Item>
            <Text>
              By continuing, you've agreed to Tradester Inc's{" "}
              <a href="/terms">Terms</a> and{" "}
              <a href="/conditions">Conditions</a>.
            </Text>
          </Form.Item>
        </Form>
      </div>
      <ForgotPassword open={open} close={() => setOpen(false)} />
    </div>
  );
};

export default Login;
