import React, { useContext, useState } from "react";
import { Typography, Input, Button, message, Select, Radio, Badge } from "antd";
import {
  CopyOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Gradientbutton from "../../comp/reuse/gradientbutton";
import Mtooltip from "../../comp/reuse/tooltip";
import Myalert from "../../comp/reuse/alert";
import { Context } from "../../AppContext";
import { set } from "js-cookie";
import USDT from "./comp/modals/usdt";
import { useSearchParams } from "react-router-dom";
import usePostPrivate from "./../../hooks/usePostPrivate";

const { Title, Text } = Typography;

const ConfirmID = () => {
  const [searchParams] = useSearchParams();
  const amount = searchParams.get("amount");
  const [inputValue, setInputValue] = useState("");
  const { postData, message:dbMessage } = usePostPrivate();

  async function handleAddFunds(params) {
    const data = {
      amount,
      transaction_id: inputValue,
    };
    await postData("addfunds", data, () => {});
  }

  function handlePaste() {
    navigator.clipboard
      .readText()
      .then((text) => {
        setInputValue(text);
        message.success("Pasted from clipboard!");
      })
      .catch((err) => {
        message.error("Failed to read clipboard contents.");
      });
  }
  return (
    <div className="w-100">
      <Title className="m-0 text-center" level={4}>
        COMFIRM TRANSACTION
      </Title>
      <div className="mt-4">
        <Myalert
          title={""}
          showIcon={true}
          desc={
            "Copy transaction ID from your wallet and paste here to confirm your deposit."
          }
        />
      </div>

      <div className="" style={{ marginTop: 30 }}>
        <div
          className="input-copy-container "
          style={{
            maxWidth: 300,
            minWidth: 200,
            marginTop: 16,
            borderRadius: 8,
          }}
        >
          <Title level={5}>
            ENTER TRANSACTION ID:{" "}
            <Mtooltip title={"Copy transanction ID and paste here"} />
          </Title>
          <Input
            value={inputValue}
            required
            onChange={(e) => setInputValue(e.target.value)}
            style={{
              borderRadius: "4px",
              border: "1px solid #d9d9d9",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
            suffix={
              <Button
                icon={<FileTextOutlined />}
                onClick={handlePaste}
                type="default"
                variant="outlined"
                size="small"
                style={{ borderRadius: 4 }}
              >
                PASTE
              </Button>
            }
          />
        </div>
        <div className="mt-4">
          {dbMessage && (
            <Myalert
              title={""}
              showIcon={false}
              desc={dbMessage?.msg}
              type={dbMessage?.type}
            />
          )}
        </div>
        <Gradientbutton
          btntext="CONFIRM"
          mClasses="mt-5 d-flex align-items-center justify-content-end w-100"
          size="large"
          //   loading={true}
          callBack={handleAddFunds}
          disabled={!inputValue}
        />
      </div>
    </div>
  );
};

export default ConfirmID;
