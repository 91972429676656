import { Modal } from "antd";
import React, { useState } from "react";

const Authorized = ({ open = false, close = () => null, text }) => {
  return (
    <Modal title="Action Restricted" open={open} onCancel={close} footer={null}>
      {text || (
        <>
          Only registered and verified users can use this action. Go to{" "}
          <a href="/login">Login</a> or <a href="/register">Register</a> to get
          started.
        </>
      )}
    </Modal>
  );
};

export default Authorized;
