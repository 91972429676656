import { Alert, Typography } from "antd";
import React from "react";
const { Title, Text } = Typography;
const Myalert = ({
  title,
  type = "info",
  desc,
  closable = false,
  showIcon = true,
}) => {
  return (
    <div className="my-2 ">
      <Alert
        closable={false}
        message={<Title level={5}>{title}</Title>}
        description={<div dangerouslySetInnerHTML={{ __html: desc }}></div>}
        type={type}
        showIcon={false}
        className="py-2"
      />
    </div>
  );
};

export default Myalert;
