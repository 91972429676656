import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

const Mtooltip = ({title}) => {
  return (
    <Tooltip className="ml-1" title={title}>
      <InfoCircleOutlined style={{ marginLeft: 4, fontSize: 12 }} />
    </Tooltip>
  );
};

export default Mtooltip;
