import { Button, Modal, notification } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

const App = createContext();
export function Context(params) {
  return useContext(App);
}
export default function AppContextProvider({ children }) {
  const [is500Error, setIs500Error] = useState(false);
  const [globalLoading, setGLobalLoading] = useState(true);
  const [globalLoadBtn, setGlobalLoadBtn] = useState(false);
  const [globalPrivateLoading, setGLobalPrivateLoading] = useState(true);
  const [feedback, setFeedback] = useState();
  const [user, setUser] = useState();
  const [app, setApp] = useState({});
  const [country, setCountry] = useState("");
  const [isUser, setIsUser] = useState(false);
  const [app_data, setAppData] = useState({});

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function modalAction(
    type,
    message,
    callBack = () => null,
    btnTxt = "Ok",
    btnType = "danger"
  ) {
    Modal[type]({
      //   title: "This is a notification message",
      content: (
        <div>
          <p
            style={{ fontWeight: "bold" }}
            className="mt-4 "
          >
            {message}
          </p>
        </div>
      ),
      okText: btnTxt,
      okType: btnType,
      closable: true,
      onOk: callBack,
      // footer: null,
    });
  }

  return (
    <App.Provider
      value={{
        modalAction,
        country,
        setCountry,
        app,
        setApp,
        user,
        setUser,
        setIs500Error,
        screenWidth,
        is500Error,
        globalLoading,
        feedback,
        setFeedback,
        setGLobalLoading,
        globalPrivateLoading,
        setGLobalPrivateLoading,
        setGlobalLoadBtn,
        globalLoadBtn,
        isUser,
        setIsUser,
        app_data,
        setAppData,
      }}
    >
      {children}
    </App.Provider>
  );
}
