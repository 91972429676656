import React, { useState } from "react";
import { Typography, Input, Button, Form, Checkbox, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import Gradientbutton from "../../comp/reuse/gradientbutton";
import Myalert from "../../comp/reuse/alert";
import usePost from "../../hooks/usePost";
import Cookies from "js-cookie";

const { Title, Text } = Typography;

const Adminlogin = () => {
  const [open, setOpen] = useState(false);
  const { postData, message } = usePost();
  const onFinish = async (values) => {
    const { email, password } = values;
    const data = {
      email,
      password,
    };
    await postData("admin-login", data, (d) => {
      Cookies.set("admin", d.token, { expires: 30 });
      window.location.href = "/admin";
    });
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Please check the form and try again.");
  };

  return (
    <div className="w-100">
      <Title className="m-0 text-center" level={4}>
        ADMIN LOGIN
      </Title>
      <div className="mt-4">
        {message && (
          <Myalert
            type={message?.type}
            showIcon={false}
            title={""}
            desc={message?.msg}
          />
        )}
        <Form
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          size="large"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
            style={{ marginBottom: 24 }}
          >
            <Input prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            style={{ marginBottom: 24 }}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item>
            <Gradientbutton type={true} btntext="LOGIN" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Adminlogin;
