import React, { useEffect, useState } from "react";
import { Table, Input, Form, Badge, Button, Typography } from "antd";
import { CheckCircleFilled, DeleteFilled } from "@ant-design/icons";
import usePostPrivate from "../../../hooks/usePostPrivate";
import { Context } from "../../../AppContext";
import Myalert from "../../../comp/reuse/alert";
const { Title } = Typography;
const UserTransactions = ({
  history = [],
  pageData = {},
  callBack = async () => null,
}) => {
  const { currentPage, totalPages } = pageData;
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState();
  const [tPages, setTPages] = useState();
  const { modalAction } = Context();
  const { postData, message } = usePostPrivate();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 60,
      render: (text) => <a>{text}</a>,
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 60,
      render: (text) => <a>{text}</a>,
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 60,
      render: (text) => (
        <a>{text.length > 15 ? `${text.slice(0, 12)}...` : text}</a>
      ),
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 80,
      render: (amount) => `$${amount}`,
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: 120,
      sorter: (a, b) => new Date(a.time) - new Date(b.time),
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 80,
      filters: [
        { text: "Deposit", value: "deposit" },
        { text: "Withdraw", value: "withdraw" },
        { text: "Referral", value: "referral" },
        { text: "Trade", value: "trade" },
        { text: "Trade Fee", value: "trade fee" },
      ],
      onFilter: (value, record) => record.type.includes(value),
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 80,
      sorter: (a, b) => a.status.localeCompare(b.status),
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Declined", value: "declined" },
        { text: "Completed", value: "completed" },
      ],
      onFilter: (value, record) => record.status.includes(value),
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (_, record) => (
        <>
          {
            <div className="d-flex ">
              <Button
                type="primary"
                style={{ marginRight: 8 }}
                onClick={async () =>
                  modalAction(
                    "warning",
                    `Are you sure you want to confirm this transaction with id ${record?.id}?`,
                    async () => await updateTransaction("completed", record.id)
                  )
                }
                disabled={record.status !== "pending"}
              >
                <CheckCircleFilled />
              </Button>
              <Button
                type="danger"
                onClick={async () =>
                  modalAction(
                    "warning",
                    `Are you sure you want to decline this transaction with id ${record?.id}?`,
                    async () => await updateTransaction("declined", record.id)
                  )
                }
                disabled={record.status !== "pending"}
              >
                <DeleteFilled />
              </Button>
            </div>
          }
        </>
      ),
      onCell: () => ({
        style: { padding: 8, textAlign: "center" },
      }),
      align: "center",
    },
  ];
  useEffect(() => {
    setDataSource(history);
  }, [history]);
  useEffect(() => {
    setPage(currentPage);
    setTPages(totalPages);
  }, [pageData]);
  async function updateTransaction(status, id) {
    await postData("admin-transaction", { status, id }, async () => {
      const data = {
        showLoading: false,
        page,
      };
      await callBack({ ...data });
    });
  }
  return (
    <div className="w-100 text-center">
      <Title className="m-0 text-center" level={4}>
        TRANSACTION HISTORY
      </Title>
      <div className="my-5">
        {message && (
          <Myalert showIcon={true} type={message?.type} desc={message?.msg} />
        )}
      </div>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        rowClassName="editable-row"
        pagination={{
          current: page, // Current page
          pageSize: 10, // Number of items per page
          total: tPages, // Total number of items (from the server)
          onChange: (page) => {
            setPage(page); // Update the page state
            callBack({ page, showLoading: false }); // Fetch data for the new page
          },
        }}
        scroll={{ x: 800 }}
      />
    </div>
  );
};

export default UserTransactions;
