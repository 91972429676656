import React from "react";
import { FloatButton, Modal } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useState } from "react";
import Authorized from "./authorized";

const Floadingbtn = ({ isUserVerified = false, phone }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleClick = () => {
    if (isUserVerified) {
      window.open("https://wa.me/" + phone, "_blank");
    } else {
      setIsModalVisible(true);
    }
  };

  return (
    <>
      <FloatButton
        icon={<WhatsAppOutlined />}
        type="primary"
        shape="circle"
        style={{
          backgroundColor: "linear-gradient(90deg, #1890ff, #ff4d4f)",
          borderColor: "#25D366",
          position: "fixed",
          bottom: 50,
          left: 20,
        }}
        onClick={handleClick}
      />
      <Authorized
        open={isModalVisible}
        close={() => setIsModalVisible(false)}
      />
    </>
  );
};

export default Floadingbtn;
