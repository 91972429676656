import React, { useState, useEffect } from "react";
import { Typography, Input, Button, Form, message } from "antd";
import {
  UserOutlined,
  LockOutlined,
  SmileOutlined,
  MailOutlined,
} from "@ant-design/icons";
import Gradientbutton from "../../comp/reuse/gradientbutton";
import Mtooltip from "../../comp/reuse/tooltip";
import usePost from "../../hooks/usePost";
import axios from "axios";
import Myalert from "../../comp/reuse/alert";
import { useSearchParams } from "react-router-dom";

const { Title, Text } = Typography;

const Reg = () => {
  const [password, setPassword] = useState("");
  const [passwordStatus, setPasswordStatus] = useState({
    length: false,
    number: false,
    specialChar: false,
  });
  const [searchParams] = useSearchParams();
  const refParam = searchParams.get("ref");
  const [ref, setRef] = useState();
  const { postData, message: serverMessage } = usePost();
  const [form] = Form.useForm();

  useEffect(() => {
    const length = password.length >= 6;
    const number = /\d/.test(password);
    const specialChar = /[!@#$%^&*]/.test(password);

    setPasswordStatus({ length, number, specialChar });
  }, [password]);
  useEffect(() => {
    if (refParam) {
      setRef(refParam);
      form.setFieldsValue({ referralCode: refParam }); // Dynamically set the referralCode field value
    }
  }, [refParam, form]);
  const validatePassword = (rule, value) => {
    if (!value) {
      return Promise.reject("Password is required");
    }
    if (
      !passwordStatus.length ||
      !passwordStatus.number ||
      !passwordStatus.specialChar
    ) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    const result = await axios.get("https://ipapi.co/json");
    const { country_name, country_code } = result.data;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { email, name, password } = values;
    const data = {
      email,
      name,
      password,
      code: ref,
      country_name,
      country_code,
      timezone,
    };
    await postData("register", data, (d) => {
      window.location.href = "/verify-email?token=" + d;
    });
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Please check the form and fill in the required details.");
  };
  return (
    <div className="w-100">
      <Title className="m-0 text-center" level={4}>
        CREATE ACCOUNT
      </Title>
      <div className="mt-4">
        <div className="my-4">
          <Text className="">
            Already have an account? <a href="/login">Login</a>
          </Text>
        </div>

        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          size="large"
          initialValues={{ referralCode: ref }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
            style={{ marginBottom: 24 }}
          >
            <Input prefix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
            style={{ marginBottom: 24 }}
          >
            <Input prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ validator: validatePassword }]}
            hasFeedback
            validateTrigger="onBlur"
            style={{ marginBottom: 10 }}
          >
            <Input.Password
              prefix={<LockOutlined />}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.password !== currentValues.password
            }
            noStyle
          >
            {({ getFieldValue, isFieldTouched }) =>
              isFieldTouched("password") && (
                <div style={{ marginBottom: 24 }}>
                  <span>
                    <span
                      style={{ color: passwordStatus.length ? "green" : "red" }}
                    >
                      Password must be at least 6 characters long
                    </span>
                    ,{" "}
                    <span
                      style={{ color: passwordStatus.number ? "green" : "red" }}
                    >
                      contain at least one number
                    </span>
                    ,{" "}
                    <span
                      style={{
                        color: passwordStatus.specialChar ? "green" : "red",
                      }}
                    >
                      and contain at least one special character
                    </span>
                    .
                  </span>
                </div>
              )
            }
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            validateTrigger="onBlur"
            style={{ marginBottom: 24 }}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item
            label={
              <>
                Referral Code <Mtooltip title={"Get code from referrer."} />
              </>
            }
            rules={[{ required: true, message: "Please input code!" }]}
            name="referralCode"
            style={{ marginBottom: 24 }}
          >
            <Input
              onChange={(e) => setRef(e.target.value)}
              prefix={<SmileOutlined />}
            />
          </Form.Item>
          {serverMessage && (
            <Myalert
              showIcon={true}
              type={serverMessage?.type}
              desc={serverMessage?.msg}
            />
          )}
          <Form.Item>
            <Text>
              By continuing, you've agreed to Tradester Inc's{" "}
              <a href="/terms">Terms</a> and{" "}
              <a href="/conditions">Conditions</a>.
            </Text>
          </Form.Item>
          <Form.Item>
            <Gradientbutton type={true} btntext="CREATE ACCOUNT" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Reg;
